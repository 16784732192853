import { API_KEY_HEADER } from '@src/pages/api/constants';

export async function saveVariantIDCount(variantID, experimentID, secretKey) {
  try {
    console.log({
      message: 'saveVariantIDCount: saving variantID count',
      metadata: { variantID, experimentID },
    });

    const response = await fetch(
      `${process.env.NEXT_PUBLIC_BASE_URL}/api/saveVariantIDCount`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          [API_KEY_HEADER]: secretKey,
        },
        body: JSON.stringify({
          experimentID,
          variantID: variantID.toString(),
        }),
      },
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json(); // assuming your API returns a JSON response
    return data; // use or return the data as needed
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
