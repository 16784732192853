import Image from 'next/image';
import FadeInFromBottom from '../animations/fadeInFromBottom';
import { useTranslation } from 'next-i18next';

const HowItWorks = () => {
  const { t } = useTranslation('index');
  return (
    <div className="section wf-section">
      <div className="content">
        <div className="block-heading">
          <h2 className=" heading">{t('ourProcess')}</h2>
          <p className="text-xl">
            {t(
              'Our AI business plan generator guides you through a few questions, once done a business plan example will be generated',
            )}
          </p>
        </div>
        <div className="w-layout-grid grid-2-columns">
          <FadeInFromBottom>
            <div className="sequence shadow-on-hover">
              <div className="icon-sequence-bg">
                <Image
                  src="/img/fact_check.svg"
                  width={40}
                  height={40}
                  alt=""
                  className="icon-sequence"
                />
                <div className="number-sequence-bg">
                  <div className="number-sequence">1</div>
                </div>
              </div>
              <h6 className="max-w-md">{t('firstTopicHowItWorks')}</h6>
              <p>
                {t(
                  'Only basic business information is required e.g. business name, number of employees, and a little financial data',
                )}
              </p>
            </div>
          </FadeInFromBottom>

          <FadeInFromBottom>
            <div className="sequence shadow-on-hover">
              <div className="icon-sequence-bg">
                <Image
                  src="/img/edit.svg"
                  width={40}
                  height={40}
                  alt=""
                  className="icon-sequence"
                />
                <div className="number-sequence-bg">
                  <div className="number-sequence">2</div>
                </div>
              </div>
              <h6 className="heading-sequence">{t('Edit and Save')}</h6>
              <p>
                {t(
                  'Once your AI plan is generated you can easily edit the plan with our "Talk To Plan" feature. You can input what you want changed and AI will take care of the rest',
                )}
              </p>
            </div>
          </FadeInFromBottom>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
