import FadeInFromBottom from '../../animations/fadeInFromBottom';
import ExamplePlanShowcase from './ExamplePlanShowCase';

const ExamplePlanShowcases = () => {
  const starterPlanContents = [
    {
      title: 'Business Overview',
      text: "Welcome to May's Diner, a family restaurant located in the heart of Atlanta, Georgia. We are an upcoming uniquely-themed business that is dedicated to providing our customers with the best dining experience. Our physical location is designed to cater to families who are looking for a cozy and comfortable environment to enjoy their meals. We offer breakfast, lunch, and dinner services to our customers, and we are proud to have a team of 10 dedicated employees who are committed to providing excellent customer service.",
    },
    {
      title: 'Business Origins',
      text: "May's Diner was founded with the vision of providing our customers with a unique dining experience that is both affordable and enjoyable. Our founder, May, has always been passionate about cooking and has spent years perfecting her recipes. She wanted to share her love for food with the world and decided to open up her own restaurant.",
    },
    {
      title: 'Competitive Advantage',
      text: "At May's Diner, we believe that our competitive advantage lies in our ability to provide our customers with a unique dining experience. We understand that families are looking for a comfortable and cozy environment to enjoy their meals, and we have designed our physical location to cater to their needs. Our menu features a variety of dishes that are made with fresh ingredients and are inspired by traditional family recipes.",
    },
  ];

  const professionalPlanContents = [
    {
      title: 'Business Overview',
      text: "May's Diner is an upcoming dining establishment located in the heart of Atlanta, Georgia. Our main focus is to serve delicious breakfast, lunch, and dinner to our valued customers in a warm and welcoming environment. We are a team of five dedicated staff members, committed to providing exceptional service and mouth-watering meals. Our primary customer segments include families who are seeking a comfortable and friendly dining experience.",
    },
    {
      title: 'Business Origins',
      text: "The concept of May's Diner was born out of a desire to create a dining space that offers both quality and comfort. We recognized a gap in the market for a diner that not only serves exceptional food but also provides a homely atmosphere for families to connect and bond over meals. Our physical location is strategically situated in a family-friendly neighborhood, making it convenient for our primary customer segment.",
    },
    {
      title: 'Competitive Advantage',
      text: "The distinguishing factor that sets May's Diner apart from other dining establishments is our commitment to quality and service. Our key success factors are rooted in the culinary skills of our excellent chef, who has years of experience in the food industry. Our chef's expertise allows us to offer a diverse menu that caters to a wide range of tastes and preferences. Additionally, we believe in the power of excellent service and strive to ensure that every customer feels valued and welcomed in our establishment.",
    },
  ];

  return (
    <div className="flex flex-col lg:flex-row justify-between items-start gap-[85px] self-center w-full px-2 md:px-4 py-2">
      <FadeInFromBottom>
        <ExamplePlanShowcase
          package="Starter"
          heading="Executive Summary"
          contentsList={starterPlanContents}
          numberOfPages={10}
          numberOfSections={11}
          aiModelUsed="Decent"
        />
      </FadeInFromBottom>

      <FadeInFromBottom>
        <ExamplePlanShowcase
          package="Professional"
          heading="Executive Summary"
          contentsList={professionalPlanContents}
          numberOfPages={20}
          numberOfSections={18}
          aiModelUsed="Best"
        />
      </FadeInFromBottom>
    </div>
  );
};

export default ExamplePlanShowcases;
