import React from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/navigation';

interface Content {
  title: string;
  text: string;
}

interface ExamplePlanCardProps {
  package: 'Starter' | 'Professional';
  heading: string;
  contentsList: Content[];
  numberOfPages: number;
  numberOfSections: number;
  aiModelUsed: string;
}

const ExamplePlanShowcase: React.FC<ExamplePlanCardProps> = ({
  package: planPackage,
  heading,
  contentsList,
  numberOfPages,
  numberOfSections,
  aiModelUsed,
}) => {
  const router = useRouter();
  const { t } = useTranslation('examplePlanShowcase');
  const badges = [
    t(`${numberOfPages} Pages`),
    t(`${numberOfSections} Sections`),
    t(`AI Model Used: ${aiModelUsed}`),
  ];

  const customStyleByPackageForPlanHeading =
    planPackage === 'Starter'
      ? 'bg-white text-core-blue-1 border-core-blue-1 border-[1px]'
      : 'bg-core-blue-1 text-white shadow-core-blue';

  const customStyleByPackgeForBadge =
    planPackage === 'Starter'
      ? 'bg-white text-core-blue-1 border-core-blue-1 border-[1px]'
      : 'bg-core-blue-1 text-white';

  const customStyleByPackgeForCard =
    planPackage === 'Starter'
      ? 'shadow-full-core-black'
      : 'shadow-full-core-blue';

  return (
    <div
      onClick={() => {
        router.push(
          `form/example-plan?packagePlan=${planPackage.toLowerCase()}&from=index`,
        );
      }}
      className={`font-segoe w-full sm:w-[560px] cursor-pointer mt-8 relative bg-white rounded-lg transform transition duration-300 ease-in-out hover:shadow-none ${customStyleByPackgeForCard} px-6 pt-[40px] sm:pt-[76px] pb-5 flex flex-col justify-between`}
    >
      <div
        className={`${customStyleByPackageForPlanHeading} font-poppins font-bold text-base sm:text-xl rounded-[20px] p-4 absolute top-[-25px] left-[60px]`}
      >
        {t(`${planPackage} Plan`)}
      </div>
      <div className="text-black">
        <p className="text-2xl sm:text-3xl mb-2">{t(heading)}</p>
        {contentsList.map((content) => (
          <React.Fragment key={content?.title}>
            <p className="text-xl sm:text-2xl font-semibold mb-2">
              {t(content.title)}
            </p>
            <p className="text-xs sm:text-sm mb-4 text-plan-content-1">
              {t(content.text)}
            </p>
          </React.Fragment>
        ))}
      </div>
      <div className="flex flex-wrap justify-between gap-y-2 items-center mt-6 font-poppins">
        {badges.map((badgeMessage) => (
          <div
            key={badgeMessage}
            className={`${customStyleByPackgeForBadge} py-[1px] md:py-2 px-2 sm:px-4 rounded-full text-[10px] sm:text-sm`}
          >
            {badgeMessage}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExamplePlanShowcase;
