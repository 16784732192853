import { useEffect, useRef } from 'react';
import { usePostHog } from 'posthog-js/react';
import Cookies from 'js-cookie';

interface UsePostHogPageviewOptions {
  trackWhenLoggedIn?: boolean; // Defaults to false
}

export default function usePostHogPageview(
  session: any,
  options?: UsePostHogPageviewOptions,
) {
  const posthog = usePostHog();
  const hasTrackedRef = useRef(false); // Track if event has been sent

  useEffect(() => {
    const isLoggedIn = !!session;
    const shouldTrack =
      ((options?.trackWhenLoggedIn && isLoggedIn) ||
        (!options?.trackWhenLoggedIn && !isLoggedIn)) &&
      typeof window !== 'undefined' &&
      posthog &&
      !hasTrackedRef.current;

    if (shouldTrack) {
      const variantID = Cookies.get('variantID') || 'default';
      const experimentID = Cookies.get('experimentID') || 'default';
      console.log('[usePostHogPageview] variantID:', variantID);
      console.log('[usePostHogPageview] experimentID:', experimentID);

      try {
        posthog.capture('$pageview', {
          $current_url: window.location.href,
          variantID15MP: variantID,
          experimentID15MP: experimentID,
        });
        console.log(
          '[usePostHogPageview] Pageview captured with variantID and experimentID:',
          variantID,
          experimentID,
        );
        hasTrackedRef.current = true; // Set to true after capturing event
      } catch (error) {
        console.error('[usePostHogPageview] Error capturing pageview:', error);
      }
    } else {
      console.log(
        '[usePostHogPageview] Conditions not met for capturing pageview',
      );
    }
  }, [posthog, session, options?.trackWhenLoggedIn]);
}
